import "./style.css";
import * as THREE from "three";
/**
 * Debug
 */
// const gui = new dat.GUI();
const parameters = {
    materialColor: "#ffeded",
};
// gui.addColor(parameters, "materialColor").onChange(() => {
//   material.color.set(parameters.materialColor);
//   particlesMaterial.color.set(parameters.materialColor);
// });
/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");
// Scene
const scene = new THREE.Scene();
/**
 * Objects
 */
// Texture
const textureLoader = new THREE.TextureLoader();
const gradientTexture = textureLoader.load("textures/gradients/3.jpg");
gradientTexture.magFilter = THREE.NearestFilter;
// Material
const material = new THREE.MeshToonMaterial({
    color: parameters.materialColor,
    gradientMap: gradientTexture,
});
// Sun Material
const sunMaterial = new THREE.MeshBasicMaterial({
    map: textureLoader.load("textures/shape/sun.jpg"),
    transparent: true,
    opacity: 1,
    blending: THREE.AdditiveBlending, // Additive blending for the glow
});
// Earth Material
const earthMaterial = new THREE.MeshBasicMaterial({
    map: textureLoader.load("textures/shape/earth.jpg"),
    transparent: true,
    opacity: 1,
    blending: THREE.AdditiveBlending, // Additive blending for the glow
});
// Moon Material
const moonMaterial = new THREE.MeshBasicMaterial({
    map: textureLoader.load("textures/shape/moon.jpg"),
    transparent: true,
    opacity: 1,
    blending: THREE.AdditiveBlending, // Additive blending for the glow
});
// Create a sun-like sphere
// Objects
const objectsDistance = 4;
// const mesh1 = new THREE.Mesh(new THREE.TorusGeometry(1, 0.4, 16, 60), material);
const sun = new THREE.Mesh(new THREE.SphereGeometry(1.5, 32, 32), sunMaterial);
const earth = new THREE.Mesh(new THREE.SphereGeometry(1.5, 32, 32), earthMaterial);
const moon = new THREE.Mesh(new THREE.SphereGeometry(1.5, 32, 32), moonMaterial);
sun.position.x = 2;
sun.position.y = -objectsDistance * 0; // Adjust the position as needed
sun.name = "sun";
// mesh1.position.x = 2;
earth.position.x = -2;
earth.position.y = -objectsDistance * 1;
earth.name = "earth";
// mesh1.position.y = -objectsDistance * 0;
moon.position.x = 2;
moon.position.y = -objectsDistance * 2;
earth.name = "moon";
scene.add(sun, earth, moon);
const sectionMeshes = [sun, earth, moon];
/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
directionalLight.position.set(1, 1, 0);
scene.add(directionalLight);
/**
 * Particles
 */
// Geometry
const particlesCount = 200;
const positions = new Float32Array(particlesCount * 3);
for (let i = 0; i < particlesCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] =
        objectsDistance * 0.5 -
            Math.random() * objectsDistance * sectionMeshes.length;
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
}
const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03,
});
// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial);
scene.add(particles);
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};
window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 6;
cameraGroup.add(camera);
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
/**
 * Scroll
 */
let scrollY = window.scrollY;
let currentSection = 0;
window.addEventListener("scroll", () => {
    scrollY = window.scrollY;
    const newSection = Math.round(scrollY / sizes.height);
    if (newSection != currentSection) {
        currentSection = newSection;
        // gsap.to(sectionMeshes[currentSection].rotation, {
        //   duration: 1.5,
        //   ease: "power2.inOut",
        //   x: "+=6",
        //   y: "+=3",
        //   z: "+=1.5",
        // });
    }
});
/**
 * Cursor
 */
const cursor = {
    x: 0,
    y: 0,
};
window.addEventListener("mousemove", (event) => {
    cursor.x = event.clientX / sizes.width - 0.5;
    cursor.y = event.clientY / sizes.height - 0.5;
});
/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;
const sunRotationSpeed = 0.005; // Adjust the rotation speed as needed
const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;
    // Animate camera
    camera.position.y = (-scrollY / sizes.height) * objectsDistance;
    const parallaxX = cursor.x * 0.5;
    const parallaxY = -cursor.y * 0.5;
    cameraGroup.position.x +=
        (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
    cameraGroup.position.y +=
        (parallaxY - cameraGroup.position.y) * 5 * deltaTime;
    // Rotate the sun around its own axis
    // Animate meshes
    //   console.log(sectionMeshes, "sectionMeshes");
    for (const mesh of sectionMeshes) {
        // mesh.rotation.x += deltaTime * 0.1;
        mesh.rotation.y += deltaTime * 0.12;
    }
    // Render
    renderer.render(scene, camera);
    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};
tick();
